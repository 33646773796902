import AboutMDX from "../content/about"
import Layout from "../components/layout"
import React from "react"

export default () => {
  return (
    <Layout>
      <AboutMDX />
    </Layout>
  )
}
