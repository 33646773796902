import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from "react"
import styled from "@emotion/styled"

const RasterImg = ({height="100%", roundEdges=false, src, width="100%", contain=null}) => {

  const data = useStaticQuery(
    graphql`
      query {
        allFile {
          edges {
            node {
              name
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  const image = data.allFile.edges.find(
    edge => edge.node.name === src)?.node?.childImageSharp?.fluid;

  const NewImg = styled(Img)`
    height: ${props => `${props.height} !important` };
    width: ${props => `${props.width} !important` };

    img {
      border: ${props => props.roundEdges ? "9px solid transparent": "none"};
      border-radius: ${props => props.roundEdges ? "30px": "0px"};
      height: ${props => `${props.height} !important` };
      width: ${props => `${props.width} !important` };
      max-height: ${props => `${props.height} !important` };
      max-width: ${props => `${props.width} !important` };
      object-fit: ${props => `${props.contain ? "contain" : "cover"} !important` };
    }
  `;
  return <NewImg contain={contain} height={height} roundEdges={roundEdges} width={width} Tag="div" alt={src} fluid={image} />
}

export default RasterImg;
