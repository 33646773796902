import RasterImg from "./raster-img"

import { Col, Row } from "react-bootstrap"
import React from "react"
import styled from "@emotion/styled"

const ImgSection = ({ img, alt="", contain=null, imgHeight="auto", imgTop = false, imgWidth="auto", markdown=null, roundEdges=false, smallImage = false, children }) => {
  const Markdown = markdown;

  const rasterImg = <RasterImg src={img} alt={alt} height={imgHeight} roundEdges={roundEdges} width={imgWidth} />

  const WideSection = () => {return <>
    <Col md={smallImage ? 6 : 7} className="p-0">
      { Markdown && <Markdown />}
      { children }
    </Col>
    <Col xs="10" md={smallImage ? 3 : 4} className="align-items-center d-flex flex-column justify-content-center offset-md-1 p-0 mt-5 mt-md-0">
      {rasterImg}
    </Col>
  </>};

  const NarrowSection = () => { return <>
      <Col className="d-flex flex-column justify-content-center mt-3 mt-md-0 p-3">
        {rasterImg}
      </Col>
      <Col>
        { Markdown && <Markdown />}
        { children }
      </Col>
    </>};

  let Section = imgTop ? NarrowSection : WideSection

  return (
    <StyledRow imgTop={imgTop} className={`justify-content-center m-0 p-0 sub-block`} >
      <Section />
    </StyledRow>
  )
}

const StyledRow = styled(Row)`
  flex-direction: ${props => props.imgTop ? "column" : "row"};
`;

export default ImgSection;
