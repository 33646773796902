import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Col, Row } from "react-bootstrap";
import Content from "../components/content";
import ImgSection from "../components/img-section";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="alternating-sections alt-order-2">
      <Content maxWidth="1122px" mdxType="Content">
        <ImgSection img="Mary_Monsted_home_page_graphic" alt="Mary Monsted" imgHeight="336px" imgWidth="335px" contain roundEdges mdxType="ImgSection">
          <h1>{`The Pianist`}</h1>
          <p>{`Mary was born in Fergus Falls, Minnesota. She attended Luther College in Decorah, lowa between 1969-1973. Moved to New Orleans, Louisiana in 1973 to teach and earn a Master of Education Degree in Curriculum and Instruction from the University of New Orleans. For the next 35 years, Mary taught as the Lead Teacher for Early Childhood in five different schools: Lawless Elementary, St. Mark’s Catholic School, Academy of Sacred Heart, Tulane University’s Newcomb Nursery School, and St. Andrew’s Episcopal School.`}</p>
          <p>{`In 1982, she married T. Scott Monsted and had two children, Laura and Matt. Both are married and have given her a life of joy with her grandchildren. Mary and Scott still live in the New Orleans Metro Area and in Pass Christian, Mississippi.`}</p>
        </ImgSection>
      </Content>
      <Content className="gray-links" maxWidth="1122px" mdxType="Content">
        <h1>{`Special Thanks to:`}</h1>
        <p>{`Mike Harvey as Recording engineer, who mixed, and mastered Mary’s selections at Nola Recording Studios, New Orleans, LA. Visit his website at, `}<a parentName="p" {...{
            "href": "http://www.nolarecordingstudios.com"
          }}>{`www.nolarecordingstudios.com`}</a></p>
        <p>{`Kristine Cerniglia for her creative inspiration and graphic designs. She has provided invaluable encouragement through this musical process. Visit her website at `}<a parentName="p" {...{
            "href": "http://www.kcerniglia.com"
          }}>{`www.kcerniglia.com`}</a></p>
        <p>{`David Caruso, Creative Director and CEO of Agent C. Advertising for website design, development, and CD booklet design and printing. Visit `}<a parentName="p" {...{
            "href": "http://www.agentcadvertising.com"
          }}>{`www.agentcadvertising.com`}</a>{` to view his work.`}</p>
        <p>{`And last but not least, thanks go to my parents and family for all of their help, support, and encouragement to pursue a musical and teaching career. My dad, Raymond M. Runningen, for being my role model. He had a strong Nordic work ethic and stressed the importance of faith, family, and community service. My mom, Joan E. Mohagen-Runningen, was also a Norwegian through and through. She encouraged values of fairness, friendship, independence, acceptance, and the importance of excellence in all that we do. I have tried to preserve this same safe and encouraging environment for our children, Laura and Matt Monsted, with the help and support of my husband, T. Scott Monsted. What we learn as a child guides us as adults, and through music, I am happy to share that with you, my loyal listeners.`}</p>
      </Content>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      